import * as React from "react"
import PageLayout from "../../components/pageLayout";
import Seo from "../../components/seo";

const RadgivingPage = () => {
    return <PageLayout>
        <Seo title={"Rådgiving"}/>
        <div className="relative py-16 bg-secondary-light overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Tjenester:
            </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              Rådgiving
            </span>
                    </h1>
                </div>
                <div className="mt-6 prose prose-primary prose-lg text-gray-200 mx-auto">
                    <ul>
                        <li><strong>Operasjonsmanual:</strong> Skal din bedrift eller organisasjon starte med droner kommersielt vil det som oftes kreves en spesial tillatelse fra Luftfartstilsynet. Denne tillatelsen vil bli gitt basert på en godkjent operasjonmanual. Sammen med kunde går vi igjennom hva utstyr som best dekker deres behov, og skreddersyr så en operasjonsmanual etter kundens utstyr, operasjoner og personell. Resultet er kvalitetssystem som ivaretar trygghet og gjør at kunden kan utføre operasjoner så effektivt og enkelt som mulig.</li>
                        <li><strong>Konsulenttjenester:</strong> Planlegger din bedrift eller organisasjon innkjøp av droner eller programvare, og ønsker rådgivning på kva som er den mest kosteffektive løsningen?
                            Vi leverer ulike rådgivningstjenester, bl.a:
                            <ul>
                                <li>Hvordan bygge opp droneoperasjon</li>
                                <li>Hvilke droner og software som gir den mest kostnadseffektive løsningen for bedriften</li>
                                <li>Holder foredrag og presentasjoner angående RPAS relatert virksomhet</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </PageLayout>
}

export default RadgivingPage